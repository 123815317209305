import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/aida.jpg" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import pal33 from "../images/palestrantes/diogogama.png" // Tell webpack this JS file uses this image

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Auditor<br></br> interno de Bem-estar</span> <br></br>
              Galinhas poedeiras
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal33} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Diogo Gama
                      </div>
                    
                    <div className="titulo inter-light">
                    Auditor certificado WFQ e AWIN
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        13 e 14 de Julho de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      385€ + IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/auditor-interno-galinhas-poedeiras.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/jmqrsMn9AH2WqyFL8" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Curso orientado a médicos e enfermeiros veterinários, engenheiros
zootécnicos, técnicos avícolas ou equivalente, com no mínimo um ano
de experiência em avicultura e que pretendam realizar auditorias
internas WFQ em galinhas poedeiras. Após a recente alteração do
Regulamento Welfair do IRTA, em vigor desde o dia 1 de Janeiro de
2023, qualquer colaborador que cumpra com funções de auditoria
interna, deve dispor de uma formação Welfare Quality® com duração
mínima de duas horas. A FarmIN quis ir mais além e proporcionar um
curso mais completo contemplando componente prática na exploração,
de forma a garantir que os seus formandos concluem o curso capazes
de realizar auditorias internas de forma autónoma.
                </div>
                <div className="logos">
                  
              
                <img src={logo2} alt="" />

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
          <div className="text inter-regular">
          Orientado a Médicos e Enfermeiros Veterinários, Engenheiros Zootécnicos, tecnicos avícolas ou equivalente com mínimo de 1 ano de experiência em avícultura e que pretendam realizar auditorias internas WFQ em galinhas poedeiras.             </div>
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 13/07/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 09:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Introdução ao BEA, protocolos WFQ e Certificação Welfair </span></p>
                                </div> 
                              
                                <div class="modulo">
                                  <p><span className="date">09:30 - 10:45</span> | <span className="mod">Módulo 2</span> - <span class="txt">Amostragem e legislação</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:15</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:15 - 13:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Indicadores de Bem-estar na exploração - Instalações</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:30 - 15:45</span> | <span className="mod">Módulo 4</span> - <span class="txt">Indicadores de Bem-estar na exploração - Saúde</span></p>
                                </div>
   
                                <div class="modulo">
                                  <p><span className="date">15:45 - 16:15</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:15 - 17:15</span> | <span className="mod">Módulo 5</span> - <span class="txt">Indicadores de Bem-estar na exploração - Comportamento</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">17:15 - 18:00</span> | <span className="mod">Módulo 6</span> - <span class="txt">Esclarecimento de dúvidas - exercício prático</span></p>
                                </div>
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 14/07/2023
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">09:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Simulação de uma auditoria - exploração avícola</span></p>
                                </div>                           
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                       
      
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/auditor-interno-galinhas-poedeiras.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/jmqrsMn9AH2WqyFL8" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal33} alt="" />

              </div>
              <div className="nome">
              Diogo Gama
                            </div>
              <div className="titulo">
              Auditor certificado WFQ e AWIN
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Welfare species Auditor -frangos de engorda e matadouro de frangos
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Welfare species Auditor - suínos de engorda
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  AWIN species Auditor - perus </p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Welfare species Auditor - bovinos de galinhas poedeiras
</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Welfare species Auditor - bovinos de carne
</p>
<p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
Welfare species Auditor - bovinos de leite
</p>
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
